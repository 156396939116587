<template>
	<div class="wrapper">
    <count-timer class="timer" :startTime="startTime"></count-timer>
		<div class="content">
			<!--画面div-->
			<div class="main-window" ref="small">
				<span class="loading-text" v-show="isDesc">{{ desc }}</span>
			</div>
			<!--小画面div-->
			<div class="sub-window" ref="large">
			</div>
		</div>
		<!--底层栏-->
		<ul class="tab-bar">
			<!-- <li :class="{ silence: true, isSilence }" @click="setOrRelieveSilence"></li> -->
			<li class="over" @click="handleOver"></li>
			<!-- <li :class="{ stop: true, isStop }" @click="stopOrOpenVideo"></li> -->
		</ul>
	</div>
</template>
<script>
import { message } from '@/components/Message';
import NERTC from 'nertc-web-sdk/NERTC';
import NIMSDK from 'nim-web-sdk-ng';
import CountTimer from './components/CountTimer.vue'
import {v4 as uuid} from 'uuid'

export default {
	name: 'single',
  props: ['toAccid'],
  emits: ['onEnd'],
  components: {CountTimer},
	data() {

    const loginInfo = JSON.parse(sessionStorage.getItem('loginInfo'))
		return {
      startTime: 0,
			isSilence: false,
			isDesc: true,
			isStop: false,
      channelName: uuid().replaceAll('-', ''),
			desc: '正在接通中...',
			client: null,
			localUid: Math.ceil(Math.random() * 1e5),
			localStream: null,
			remoteStream: null,
			call: null,
			// 以下是呼叫相关
			nim: null,
			eventList: ['logined', 'multiPortLogin', 'kicked', 'willReconnect', 'disconnect'],
			channelInfo: {}, //频道相关信息
			accToken: loginInfo.accToken,
			accId: loginInfo.accId,
      AppKey: loginInfo.appKey,
		};
	},
	mounted() {
    this.initLogin();
	},
  async beforeDestroy() {
		await this.handleOver();
	},
	methods: {
		async initLogin() {
			this.nim = NIMSDK.getInstance({
				appkey: this.AppKey,
				account: this.accId,
				token: this.accToken,
				debugLevel: 'debug',
			});
			this.eventList.forEach((key) => {
				this.nim.on(key, (res) => {
					console.log(`Receive ${key} event：`, res ? JSON.parse(JSON.stringify(res)) : res);
				});
			});
			const signalingEventList = [
				'signalingClose',
				'signalingJoin',
				'signalingInvite',
				'signalingCancelInvite',
				'signalingReject',
				'signalingAccept',
				'signalingLeave',
				'signalingCustomCommand',
				'singalingSyncChannels',
			];

			signalingEventList.forEach((key) => {
				this.nim.signaling.on(key, (res) => {
					console.log(`Receive ${key} event：`, res ? JSON.parse(JSON.stringify(res)) : res);
				});
			});
			if (this.nim.status === 'unconnected') {
				await this.nim.connect();
			}
			this.toCall();
		},
		// 组合接口, 等效于 create 创建频道 + join 加入频道 + invite 邀请他人进入频道。
		async toCall() {
      const uinfo = JSON.parse(sessionStorage.getItem('userInfo'))
			let params = {
				type: 1,
				toAccid: this.toAccid,
				requestId: this.channelName,
        attachExt: JSON.stringify({
          name: uinfo.principalName,
          phone: uinfo.phoneNumber,
        })
			};
			try {
				const data = await this.nim.signaling.callEx(params);
				this.channelInfo = data.channelInfo;
				this.initVideo();
				console.warn('创建频道成功，data：', data, 'channelId 为', this.channelInfo.channelId, 'name 为', this.channelInfo.name);
			} catch (error) {
        if(error.code === 414){
          this.isDesc = true
          this.desc = "该用户未注册"
        }
				console.warn('创建频道失败，error：', error);
				if (error.code == 10405) {
					console.warn('频道已存在，请勿重复创建');
				}
			}
		},
		// 关闭频道并离开频道
		async closeChannel() {
			// 注意 channelId 是上文 callEx 创建时得到的
			let params = {
				channelId: this.channelInfo.channelId,
			};
			try {
				await this.nim.signaling.close(params);
				console.warn('关闭频道成功');
			} catch (error) {
				console.warn('关闭频道失败，error：', error);
				if (error.code == 10406) {
					console.warn('不在频道内，无法关闭');
				}
			}
			try {
				await this.nim.signaling.leave(params);
				console.warn('离开频道成功');
			} catch (error) {
				console.warn('离开频道失败，error：', error);
				if (error.code == 10406) {
					console.warn('不在频道内');
				}
			}
		},
		// 取消邀请
		async cancelInvite() {
			let params = {
				channelId: this.channelInfo.channelId,
				toAccid: this.toAccid,
				requestId: this.channelName
			};
			try {
				let data = await this.nim.signaling.cancelInvite(params);
				console.warn('取消邀请成功，data：', data);
			} catch (error) {
				console.warn('取消邀请失败，error：', error);
				switch (error.code) {
					case 10404:
						console.warn('频道不存在');
						break;
					case 10408:
						console.warn('邀请不存在或已过期');
						break;
					case 10409:
						console.warn('邀请已经拒绝');
						break;
					case 10410:
						console.warn('邀请已经接受');
						break;
				}
			}
		},
		//初始化音视频
		initVideo() {
			// 初始化音视频实例
			console.warn('初始化音视频sdk');
			window.self = this;
			this.client = NERTC.createClient({
				appkey: this.AppKey,
				debug: true,
			});
			console.log(this.client, '0000---');
			//监听事件
			this.client.on('peer-online', (evt) => {
				console.warn(`${evt.uid} 加入房间`);
			});

			this.client.on('peer-leave', (evt) => {
				console.log('====================================');
				console.log(111);
				console.log('====================================');
				console.warn(`${evt.uid} 离开房间`);
				if (this.remoteStream.getId() === evt.uid) {
					this.remoteStream = null;
					this.isDesc = true;
          this.startTime = null
					this.desc = '通话结束';
					message(this.desc);
				}
			});

			this.client.on('stream-added', (evt) => {
				//收到房间中其他成员发布自己的媒体的通知，对端同一个人同时开启了麦克风、摄像头、屏幕贡献，这里会通知多次
				const stream = evt.stream;
				const userId = stream.getId();
				console.warn(`收到 ${userId} 的发布 ${evt.mediaType} 的通知`); // mediaType为：'audio' | 'video' | 'screen'
				const remoteStreamMap = this.client.adapterRef.remoteStreamMap;
				// 已经有两人在房间中，剔除（不能排除观众）
				if (Object.keys(remoteStreamMap).length === 2) {
					return this.handleOver();
				}
				var remoteStream = evt.stream;
				console.warn('收到对方发布的订阅消息: ', remoteStream.getId());
				if (this.remoteStream && this.remoteStream.getId() !== remoteStream.getId()) {
					console.warn('房间里第三个人加入，忽略');
					return;
				} else {
					this.remoteStream = remoteStream;
				}
				this.subscribe(remoteStream);
			});

			this.client.on('stream-removed', (evt) => {
				const remoteStream = evt.stream;
				const userId = remoteStream.getId();
				console.warn(`收到 ${userId} 的停止发布 ${evt.mediaType} 的通知`); // mediaType为：'audio' | 'video' | 'screen'
				remoteStream.stop(evt.mediaType);
			});

			this.client.on('stream-subscribed', (evt) => {
				const userId = evt.stream.getId();
				console.warn(`收到订阅 ${userId} 的 ${evt.mediaType} 成功的通知`); // mediaType为：'audio' | 'video' | 'screen'
				//这里可以根据mediaType的类型决定播放策略
				const remoteStream = evt.stream;
				this.isDesc = false;
        this.startTime = Date.now()
				//用于播放对方视频画面的div节点
				const div = this.$refs.small;
				//这里可以控制是否播放某一类媒体，这里设置的是用户主观意愿
				//比如这里都是设置为true，本次通知的mediaType为audio，则本次调用的play会播放音频，如果video、screen内部已经订阅成功，则也会同时播放video、screen，反之不播放
				const playOptions = {
					audio: true,
					video: true,
					screen: true,
				};
				remoteStream
					.play(div, playOptions)
					.then(() => {
						console.log('播放对端的流成功: ', playOptions);
						remoteStream.setRemoteRenderMode({
							// 设置视频窗口大小
							width: div.clientWidth,
							height: div.clientHeight,
							cut: true, // 是否裁剪
						});
					})
					.catch((err) => {
						console.warn('播放对方视频失败了: ', err);
					});
				//这里监听一下音频自动播放会被浏览器限制的问题（https://doc.yunxin.163.com/nertc/docs/jM3NDE0NTI?platform=web）
				remoteStream.on('notAllowedError', (err) => {
					const errorCode = err.getCode();
					const id = remoteStream.getId();
					console.log('remoteStream notAllowedError: ', id);
					if (errorCode === 41030) {
						// 页面弹筐加一个按钮，通过交互完成浏览器自动播放策略限制的接触
						// const userGestureUI = document.createElement('div');
						// if (userGestureUI && userGestureUI.style) {
						// 	userGestureUI.style.fontSize = '20px';
						// 	userGestureUI.style.position = 'fixed';
						// 	userGestureUI.style.background = 'yellow';
						// 	userGestureUI.style.margin = 'auto';
						// 	userGestureUI.style.width = '100%';
						// 	userGestureUI.style.zIndex = '9999';
						// 	userGestureUI.style.top = '0';
						// 	userGestureUI.onclick = () => {
						// 		if (userGestureUI && userGestureUI.parentNode) {
						// 			userGestureUI.parentNode.removeChild(userGestureUI);
						// 		}
						// 		remoteStream.resume();
						// 	};
						// 	userGestureUI.style.display = 'block';
						// 	userGestureUI.innerHTML = '自动播放受到浏览器限制，需手势触发。<br/>点击此处手动播放';
						// 	document.body.appendChild(userGestureUI);
						// }
					}
				});
			});

			this.client.on('uid-duplicate', () => {
				console.warn('==== uid重复，你被踢出');
			});

			this.client.on('error', (type) => {
				console.error('===== 发生错误事件：', type);
				if (type === 'SOCKET_ERROR') {
					console.warn('==== 网络异常，已经退出房间');
				}
			});

			this.client.on('accessDenied', (type) => {
        this.desc = `请授权允许访问${{'video':'摄像头','audio': '麦克风'}[type] || type}设备并刷新页面`
				console.warn(`==== ${type}设备开启的权限被禁止`);
        this.handleOver(false)
			});

			this.client.on('connection-state-change', (evt) => {
				console.warn(`网络状态变更: ${evt.prevState} => ${evt.curState}, 当前是否在重连：${evt.reconnect}`);
			});

			this.joinChannel();
		},
		returnJoin() {
      this.$emit('onEnd')
		},
		joinChannel() {
			if (!this.client) {
				message('内部错误，请重新加入房间');
				return;
			}
			console.info('开始加入房间: ', this.$route.query.channelName);
			this.client
				.join({
					channelName: this.channelName,
					uid: this.localUid,
				})
				.then((ee) => {
          console.log(ee)
					console.info('加入房间成功，开始初始化本地音视频流');
					this.initLocalStream();
				})
				.catch((error) => {
					console.error('加入房间失败：', error);
					message(`${error}: 请检查appkey或者token是否正确`);
					this.returnJoin();
				});
		},
		async initLocalStream() {
      if(!navigator.mediaDevices){
        this.isDesc = true
        this.desc = '无可用音频设备或无法获取权限'
        this.handleOver(false)
        return
      }
      try {
        const cameras = await NERTC.getCameras();    //获取可用的视频输入设备
      const microphones = await NERTC.getMicrophones();     //获取可用的麦克风设备
			//初始化本地的Stream实例，用于管理本端的音视频流
			this.localStream = NERTC.createStream({
				uid: this.localUid,
				audio: true, //是否启动mic
				video: true, //是否启动camera
				microphoneId: microphones.microphoneId,    // 麦克风设备 deviceId，通过 getMicrophones() 获取
        cameraId: cameras.cameraId             // 摄像头设备 deviceId，通过 getCameras() 获取
			});

			//设置本地视频质量
			this.localStream.setVideoProfile({
				resolution: NERTC.VIDEO_QUALITY_720p, //设置视频分辨率
				frameRate: NERTC.CHAT_VIDEO_FRAME_RATE_15, //设置视频帧率
			});
			//设置本地音频质量
			this.localStream.setAudioProfile('speech_low_quality');
			//启动媒体，打开实例对象中设置的媒体设备
			this.localStream
				.init()
				.then((ee) => {
          console.log(ee)
					console.warn('音视频开启完成，可以播放了');
					const div = self.$refs.large;
					this.localStream.play(div);
					this.localStream.setLocalRenderMode({
						// 设置视频窗口大小
						width: div.clientWidth,
						height: div.clientHeight,
						cut: true, // 是否裁剪
					});
					// 发布
					this.publish();
				})
				.catch((err) => {
					console.warn('音视频初始化失败: ', err);
					message('音视频初始化失败');
					this.localStream = null;
				});
      } catch (error) {
        this.isDesc = true
        this.desc = '无可用音频设备或无法获取权限!'
        console.log(error)
      }
		},
		publish() {
			console.warn('开始发布视频流');
			//发布本地媒体给房间对端
			this.client
				.publish(this.localStream)
				.then(() => {
					console.warn('本地 publish 成功');
				})
				.catch((err) => {
					console.error('本地 publish 失败: ', err);
					message('本地 publish 失败');
				});
		},
		subscribe() {
			this.remoteStream.setSubscribeConfig({
				audio: true,
				video: true,
			});
			this.client
				.subscribe(this.remoteStream)
				.then(() => {
					console.warn('本地 subscribe 成功');
				})
				.catch((err) => {
					console.warn('本地 subscribe 失败: ', err);
					message('订阅对方的流失败');
				});
		},
		setOrRelieveSilence() {
			const { isSilence } = this;
			this.isSilence = !isSilence;
			if (this.isSilence) {
				console.warn('关闭mic');
				this.localStream
					.close({
						type: 'audio',
					})
					.then(() => {
						console.warn('关闭 mic sucess');
					})
					.catch((err) => {
						console.warn('关闭 mic 失败: ', err);
						message('关闭 mic 失败');
					});
			} else {
				console.warn('打开mic');
				if (!this.localStream) {
					message('当前不能打开mic');
					return;
				}
				this.localStream
					.open({
						type: 'audio',
					})
					.then(() => {
						console.warn('打开mic sucess');
					})
					.catch((err) => {
						console.warn('打开mic失败: ', err);
						message('打开mic失败');
					});
			}
		},
		stopOrOpenVideo() {
			const { isStop } = this;
			this.isStop = !isStop;
			if (this.isStop) {
				console.warn('关闭摄像头');
				this.localStream
					.close({
						type: 'video',
					})
					.then(() => {
						console.warn('关闭摄像头 sucess');
					})
					.catch((err) => {
						console.warn('关闭摄像头失败: ', err);
						message('关闭摄像头失败');
					});
			} else {
				console.warn('打开摄像头');
				if (!this.localStream) {
					message('当前不能打开camera');
					return;
				}
				this.localStream
					.open({
						type: 'video',
					})
					.then(() => {
						console.warn('打开摄像头 sucess');
						const div = self.$refs.large;
						this.localStream.play(div);
						this.localStream.setLocalRenderMode({
							// 设置视频窗口大小
							width: div.clientWidth,
							height: div.clientHeight,
							cut: true, // 是否裁剪
						});
					})
					.catch((err) => {
						console.warn('打开摄像头失败: ', err);
						message('打开摄像头失败');
					});
			}
		},
		async handleOver(autoEnd = true) {
			console.warn('离开房间');
      await this.cancelInvite();
      await this.closeChannel();
      if(this.client){
        await this.client.leave();
        await this.client.destroy();
      }
      if(autoEnd){
        this.returnJoin();
      }
		},
	},
};
</script>

<style scoped lang="scss">
.wrapper {
	background-image: linear-gradient(179deg, #141417 0%, #181824 100%);
	display: flex;
	flex-direction: column;
  position: relative;

  .timer {
    position: absolute;
    left: 14px;
    top: 16px;
    z-index: 100;
  }

	.content {
		flex: 1;
		position: relative;

		.main-window {
			width: 306px;
			height: 313px;
			margin: 0 auto;
			background: #25252d;
      display: flex;
      align-items: center;
      justify-content: center;
			div{
				height: 100%;
				width: 100%;
			}
		}

		.sub-window {
			width: 165px;
			height: 95px;
			background: #25252d;
			position: absolute;
			z-index: 9;
			right: 16px;
			top: 16px;
			border: 1px solid #ffffff;
		}
	}

  .loading-text {
    display: block;
    width: 100%;
    text-align: center;
    line-height: 90px;
    font-size: 18px;
    color: #fff;
    font-weight: 400;
  }

	.tab-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
		list-style: none;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		color: #fff;
    padding-bottom: 10px;
    padding-right: 13px;

		li {
			height: 24px;
			width: 46px;
			cursor: pointer;
			//静音
			&.silence {
				background: url('./assets/chartImg/silence.png') no-repeat center;
				background-size: 60px 54px;

				&:hover {
					background: url('./assets/chartImg/silence-hover.png') no-repeat center;
					background-size: 60px 54px;
				}

				&:active {
					background: url('./assets/chartImg/silence-click.png') no-repeat center;
					background-size: 60px 54px;
				}

				&.isSilence {
					//已经开启静音
					background: url('./assets/chartImg/relieve-silence.png') no-repeat center;
					background-size: 60px 54px;

					&:hover {
						background: url('./assets/chartImg/relieve-silence-hover.png') no-repeat center;
						background-size: 60px 54px;
					}

					&:active {
						background: url('./assets/chartImg/relieve-silence-click.png') no-repeat center;
						background-size: 60px 54px;
					}
				}
			}

			//结束按钮
			&.over {
				background: url('./assets/chartImg/over.png') no-repeat center;
				background-size: 46px 24px;

				&:hover {
					background: url('./assets/chartImg/over-hover.png') no-repeat center;
          background-size: 46px 24px;
				}

				&:active {
					background: url('./assets/chartImg/over-click.png') no-repeat center;
          background-size: 46px 24px;
				}
			}

			// 停止按钮
			&.stop {
				background: url('./assets/chartImg/stop.png') no-repeat center;
				background-size: 60px 54px;

				&:hover {
					background: url('./assets/chartImg/stop-hover.png') no-repeat center;
					background-size: 60px 54px;
				}

				&:active {
					background: url('./assets/chartImg/stop-click.png') no-repeat center;
					background-size: 60px 54px;
				}

				//已经是停止状态
				&.isStop {
					background: url('./assets/chartImg/open.png') no-repeat center;
					background-size: 60px 54px;

					&:hover {
						background: url('./assets/chartImg/open-hover.png') no-repeat center;
						background-size: 60px 54px;
					}

					&:active {
						background: url('./assets/chartImg/open-click.png') no-repeat center;
						background-size: 60px 54px;
					}
				}
			}
		}
	}
}

</style>
<style>
.nertc-video-container{
	width: 100% !important;
	height: 100% !important;
}
 nertc-video-container-remote{
	width: 100% !important;
	height: 100% !important;
 }
</style>