<template>
  <div ref="container" class="importantKey w100 mt16">
    <Title :isShowSwitch="isShowSwitch" @changeSwitch="changeSwitch"  @curveFun="curveFun">服务质量</Title>
    <div class="m_xares">
      <div class="line">
        <BaseEchart :option="option1" style="width: 100%; height: 100%"></BaseEchart>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import BaseEchart from '@/components/BaseEchart';
import Title from '../components/Title';
import { fetchRegionSummaryNotations, findValueByName } from '../../../request/api';


export default {
  components: {
    BaseEchart,
    Title
  },
  data() {
    return {
      option1: null,
      showNoData: false,
      cardData: [{ name: '总人数', value: 830000 }, { name: '老人', value: 1025 }, { name: '妇女', value: 825 }, { name: '儿童', value: 2042 }, { name: '常见病', value: 159 }],
      isShowSwitch:true,
      isSwitchOpen:true,
      period:1
    };
  },
  computed: {
    ...mapState('mapData', ['addressInfo', 'mapLevel', 'mapDataArr']),
    currentMonthPeriod(){
      return this.$store.state.homePage.currentMonthPeriod
    },
    monthPeriod(){
      return this.$store.state.homePage.monthPeriod
    },
  },
  watch: {
    mapDataArr: {
      handler(){
        if(this.mapDataArr.length > 0){
          this.init()
        }
      },
      immediate: true
    },
    monthPeriod:{
      handler(){
        this.selectTime()
      },
    }
  },
  mounted() {
  },
  methods: {
    async init() {
      const data = await fetchRegionSummaryNotations({itemName: '服务质量-沟通能力;服务质量-服务态度;服务质量-服务效率;服务质量-服务满意度', fullTime: this.isSwitchOpen})
      let arry = [findValueByName('服务质量-服务态度', data?.data?.collection || []),findValueByName('服务质量-服务效率', data?.data?.collection || []),findValueByName('服务质量-沟通能力', data?.data?.collection || []),findValueByName('服务质量-服务满意度', data?.data?.collection || [])]
      this.option1 = this.getChart(arry)
      this.$store.state.homePage.block.massDor = arry[0]
    },
    getChart(arry) {
      return {
        title: [{
          x: "18%",
          y: "40%",
          //bottom: 100,
          text: '服务态度',
          textStyle: {
            fontWeight: 'normal',
            fontSize: 16,
            color: "#fff"
          },
        }, {
          x: "68%",
          y: "40%",
          text: '服务效率',
          textStyle: {
            fontWeight: 'normal',
            fontSize: 16,
            color: "#fff"
          },
        }, {
          x: "18%",
          y: "95%",
          //bottom: 100,
          text: '沟通能力',
          textStyle: {
            fontWeight: 'normal',
            fontSize: 16,
            color: "#fff"
          },
        }, {
          x: "67%",
          y: "95%",
          text: '服务满意度',
          textStyle: {
            fontWeight: 'normal',
            fontSize: 16,
            color: "#fff"
          },
        }],
        series: [
          {
            name: '速度',
            type: 'gauge',
            min: 0,
            max: 100,
            center: ['25%', '25%'], // 默认全局居中
            //splitNumber:11,
            radius: '50%',
            axisLine: {            // 坐标轴线
              lineStyle: {       // 属性lineStyle控制线条样式
                color: [[0.3, '#ff4500'], [0.7, '#4EE3FF'], [1, 'lime']],
                width: 3,
                //shadowColor : '#fff', //默认透明
                shadowBlur: 10
              }
            },
            axisLabel: {            // 坐标轴小标记
              textStyle: {       // 属性lineStyle控制线条样式
                fontWeight: 'bolder',
                color: '#fff',
                //shadowColor : '#fff', //默认透明
                shadowBlur: 10
              }
            },
            axisTick: {            // 坐标轴小标记
              length: 15,        // 属性length控制线长
              lineStyle: {       // 属性lineStyle控制线条样式
                color: 'auto',
                width: 2,
                //shadowColor : '#fff', //默认透明
                shadowBlur: 10
              }
            },
            splitLine: {//橙色分割线
              length: 20,
              lineStyle: {
                width: 3,
                color: '#FCD209',
              }
            },
            itemStyle: {//指针颜色
              color: '#1e90ff',
            },
            pointer: {//指针长短
              length: 45
            },
            detail: { formatter: '{value}', fontSize: 18, offsetCenter: [0, '50%'], color:'#4EE3FF'},
            data: [{ value: arry[0] }]
          }, {
            name: '速度',
            type: 'gauge',
            min: 0,
            max: 100,
            center: ['75%', '25%'], // 默认全局居中
            //splitNumber:11,
            radius: '50%',
            axisLine: {            // 坐标轴线
              lineStyle: {       // 属性lineStyle控制线条样式
                color: [[0.3, '#ff4500'], [0.7, '#4EE3FF'], [1, 'lime']],
                width: 3,
                // shadowColor: '#fff', //默认透明
                shadowBlur: 10
              }
            },
            axisLabel: {            // 坐标轴小标记
              textStyle: {       // 属性lineStyle控制线条样式
                fontWeight: 'bolder',
                color: '#fff',
                //shadowColor : '#fff', //默认透明
                shadowBlur: 10
              }
            },
            axisTick: {            // 坐标轴小标记
              length: 15,        // 属性length控制线长
              lineStyle: {       // 属性lineStyle控制线条样式
                color: 'auto',
                width: 2,
              }
            },
            splitLine: {//橙色分割线
              length: 20,
              lineStyle: {
                width: 2,
                color: '#FCD209',
              }
            },
            itemStyle: {//指针颜色
              color: '#1e90ff',
            },
            pointer: {//指针长短
              length: 45
            },
            detail: {
              formatter: '{value}',
              fontSize: 18,
              color:'#4EE3FF',
              offsetCenter: [0, '50%'],
            },
            data: [{
              value: arry[1],
            }]
          }, {
            name: '速度',
            type: 'gauge',
            min: 0,
            max: 100,
            center: ['25%', '78%'], // 默认全局居中
            //splitNumber:11,
            radius: '50%',
            axisLine: {            // 坐标轴线
              lineStyle: {       // 属性lineStyle控制线条样式
                color: [[0.3, '#ff4500'], [0.7, '#4EE3FF'], [1, 'lime']],
                width: 3,
                //shadowColor : '#fff', //默认透明
                shadowBlur: 10
              }
            },
            axisLabel: {            // 坐标轴小标记
              textStyle: {       // 属性lineStyle控制线条样式
                fontWeight: 'bolder',
                color: '#fff',
                //shadowColor : '#fff', //默认透明
                shadowBlur: 10
              }
            },
            axisTick: {            // 坐标轴小标记
              length: 15,        // 属性length控制线长
              lineStyle: {       // 属性lineStyle控制线条样式
                color: 'auto',
                width: 2,
                //shadowColor : '#fff', //默认透明
                shadowBlur: 10
              }
            },
            splitLine: {//橙色分割线
              length: 20,
              lineStyle: {
                width: 3,
                color: '#FCD209',
              }
            },
            itemStyle: {//指针颜色
              color: '#1e90ff',
            },
            pointer: {//指针长短
              length: 45
            },
            detail: { formatter: '{value}', fontSize: 18, color:'#4EE3FF', offsetCenter: [0, '52%']},
            data: [{ value: arry[2] }]
          }, {
            name: '速度',
            type: 'gauge',
            min: 0,
            max: 100,
            center: ['75%', '78%'], // 默认全局居中
            //splitNumber:11,
            radius: '50%',
            axisLine: {            // 坐标轴线
              lineStyle: {       // 属性lineStyle控制线条样式
                color: [[0.3, '#ff4500'], [0.7, '#4EE3FF'], [1, 'lime']],
                width: 3,
                // shadowColor: '#fff', //默认透明
                shadowBlur: 10
              }
            },
            axisLabel: {            // 坐标轴小标记
              textStyle: {       // 属性lineStyle控制线条样式
                fontWeight: 'bolder',
                color: '#fff',
                //shadowColor : '#fff', //默认透明
                shadowBlur: 10
              }
            },
            axisTick: {            // 坐标轴小标记
              length: 15,        // 属性length控制线长
              lineStyle: {       // 属性lineStyle控制线条样式
                color: 'auto',
                width: 2,
              }
            },
            splitLine: {//橙色分割线
              length: 20,
              lineStyle: {
                width: 2,
                color: '#FCD209',
              }
            },
            itemStyle: {//指针颜色
              color: '#1e90ff',
            },
            pointer: {//指针长短
              length: 45
            },
            detail: {
              formatter: '{value}',
              fontSize: 18,
              color: '#4EE3FF',
              offsetCenter: [0, '52%'],
            },
            data: [{
              value: arry[3],
            }]
          }
        ]
      };
    },
    changeSwitch(val){
      this.isSwitchOpen=val
      this.selectTime()
    },
    curveFun(name){
      this.$emit('curFun', name, i)
    },
    selectTime(){
      if(this.isSwitchOpen){
        this.period=this.monthPeriod
      }else{
        this.period=this.currentMonthPeriod
      }
      this.init()
    }
  },
};
</script>
<style lang="scss" scoped>
.importantKey {
  border: 2px solid #264471;
  box-sizing: border-box;
  position: relative;
  background: rgba(5, 41, 92, .6);
  border: 2px solid rgba(17, 94, 205, .2);

  .nullData {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .cardContainer {
    display: flex;
    gap: 8px;
    padding: 8px;
  }
}

.m_xares {
  padding: 0 5px 15px;
  text-align: center;

  .line {
    width: 100%;
    height: 368px;
    margin: 15px 0 0;
    display: inline-block;
  }
}</style>