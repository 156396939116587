import { render, staticRenderFns } from "./charRoom.vue?vue&type=template&id=573c221b&scoped=true"
import script from "./charRoom.vue?vue&type=script&lang=js"
export * from "./charRoom.vue?vue&type=script&lang=js"
import style0 from "./charRoom.vue?vue&type=style&index=0&id=573c221b&prod&scoped=true&lang=scss"
import style1 from "./charRoom.vue?vue&type=style&index=1&id=573c221b&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "573c221b",
  null
  
)

export default component.exports